import * as Yup from 'yup';
import { Field, type FieldProps } from 'formik';

import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import TransactionService from '@/services/transaction';
import OrdersService from '@/services/order';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useTranslation } from '@/hooks/translations';
import { useUserContext } from '@/contexts/user';
import { useRestaurantContext } from '@/contexts/restaurant';
import type { DownloadBrandOrderParams } from '@/interfaces/order/types';

import { TimeRangeTypes } from '@/components/TimeRange/Form/types';
import { commonExportInitialValues } from '@/components/ExportModal/data';
import { FileFormats } from '@/components/ExportModal/types';
import { formatISOTimezone } from '@/components/TimeRange/Form/utils';
import { SelectMultipleRestaurants } from '@/components/SelectMultipleRestaurants';
import CreateReportModal from '@/components/ReportModals/CreateReportModel';
import { ATTACHMENT_TYPE, REPORT_TYPE, RestaurantReportPreferences } from '@/services/utils/schedulerReport_helper';
import ScheduledReportsService from '@/services/scheduledReports';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { initialRestaurantPreferences } from '../Settings/components/RestaurantScheduledReportEditor';

enum ReportTypes {
    ORDER = 'order',
    TRANSACTION = 'transaction',
}

const ReportManagementCreateModal = ({
    setRefreshBoolean,
    value,
}: {
    setRefreshBoolean?: Dispatch<SetStateAction<boolean>>;
    value: number;
}) => {
    const transactionService = TransactionService.getInstance();
    const orderService = OrdersService.getInstance();
    const scheduledReportsService = ScheduledReportsService.getInstance();
    const { t } = useTranslation('common');
    const { user } = useUserContext();
    const { restaurant } = useRestaurantContext();

    const [showGenerateForm, setShowGenerateForm] = useState(value === 1);
    const [showScheduleForm, setShowScheduleForm] = useState(value === 2);

    useEffect(() => {
        setShowGenerateForm(value === 1);
        setShowScheduleForm(value === 2);
    }, [value]);

    const { enqueueSnackbar } = useSnackbar();

    const isBrand = restrictRoles.EXPORT_BRAND_REPORTS.includes(user?.userData?.role);

    const getDateQuery = ({ type, from, to }: { type: TimeRangeTypes; from: string | null; to: string | null }) => {
        if (type === 'all') {
            return {
                startDate: undefined,
                endDate: undefined,
                type: undefined,
            };
        }

        return {
            startDate: from ? formatISOTimezone(from) : undefined,
            endDate: to ? formatISOTimezone(to) : undefined,
            type,
        };
    };

    const handleSubmit = async (values: {
        fileFormat: FileFormats;
        type: TimeRangeTypes;
        from: string;
        to: string;
        reportType: ReportTypes;
        restaurantIds?: string[];
        reportPreferences: RestaurantReportPreferences;
        fileName?: string;
    }) => {
        const { restaurantIds, from, to, type, reportType, fileFormat, reportPreferences, fileName } = values;
        const { endDate, startDate, ...dateQuery } = getDateQuery({ from, to, type });

        let id = restaurant?.id;
        let request = transactionService.downloadRestaurantTransaction;

        const body: Omit<DownloadBrandOrderParams, 'restaurantIds'> & { restaurantIds?: string } = { fileFormat };
        body.endDate = endDate;
        body.startDate = startDate;
        body.type = dateQuery.type;
        body.fileName = fileName;
        body.reportType = reportType;

        if (showGenerateForm) {
            if (isBrand) {
                id = restaurant?.brand_id;
                body.restaurantIds = restaurantIds?.join(',');
                if (reportType === ReportTypes.ORDER) {
                    request = orderService.downloadBrandOrder;
                } else {
                    request = transactionService.downloadBrandTransaction;
                }
            } else if (reportType === ReportTypes.ORDER) {
                request = orderService.downloadRestaurantOrder;
            }

            await request(id || '', body)
                .then(() => {
                    enqueueSnackbar(t('Report generated successfully'), { variant: 'success' });
                    if (setRefreshBoolean) {
                        setRefreshBoolean((o) => !o);
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('Error generating report'), { variant: 'error' });
                });
        }

        if (showScheduleForm) {
            console.log('reportPreferences', reportPreferences);

            await scheduledReportsService
                .createRestaurantReport(
                    {
                        ...reportPreferences,
                        timezoneId: restaurant?.timezone || '',
                        attachmentType: fileFormat === FileFormats.CSV ? ATTACHMENT_TYPE.CSV : ATTACHMENT_TYPE.XLSX,
                        dateRange: reportPreferences.dateRange,
                        reportType:
                            reportType === ReportTypes.ORDER ? REPORT_TYPE.ORDER_VIEW : REPORT_TYPE.TRANSACTION_VIEW,
                    },
                    restaurant?.id || '',
                )
                .then(() => {
                    enqueueSnackbar(t('Report scheduled successfully'), { variant: 'success' });
                    if (setRefreshBoolean) {
                        setRefreshBoolean((o) => !o);
                    }
                })
                .catch(() => {
                    enqueueSnackbar(t('Error scheduling report'), { variant: 'error' });
                });
        }

        setShowGenerateForm(value === 1);
        setShowScheduleForm(value === 2);
    };

    const handleGenerateCheckboxChange = (event: any) => {
        setShowGenerateForm(event.target.checked);
    };

    const handleCheckboxChange = (event: any) => {
        setShowScheduleForm(event.target.checked);
    };

    return (
        <CreateReportModal
            extraValidations={{
                reportType: Yup.string()
                    .oneOf(Object.values(ReportTypes), t('Please select one of the given values'))
                    .required(t('Report type is required')),
                restaurantIds: isBrand ? Yup.array().of(Yup.string().required()).nullable() : Yup.string().nullable(),
            }}
            initialValues={{
                ...commonExportInitialValues,
                reportType: ReportTypes.ORDER,
                reportPreferences: initialRestaurantPreferences,
            }}
            onConfirm={handleSubmit}
            reportTypes={[
                { label: t('Order Report'), value: ReportTypes.ORDER },
                { label: t('Transaction Report'), value: ReportTypes.TRANSACTION },
            ]}
            timeSelectEnabled
            title={t('New Report')}
            showGenerateForm={showGenerateForm}
            handleGenerateCheckboxChange={handleGenerateCheckboxChange}
            showScheduleForm={showScheduleForm}
            handleCheckboxChange={handleCheckboxChange}
        >
            {isBrand && (
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <FormLabel>{t('Restaurants')}</FormLabel>
                        <Field name="restaurantIds">
                            {({ field: { name }, meta, form: { setFieldValue } }: FieldProps) => (
                                <SelectMultipleRestaurants
                                    onChange={(value) => {
                                        setFieldValue(name, value);
                                    }}
                                    restaurantListToExport={meta.value}
                                />
                            )}
                        </Field>
                    </FormControl>
                </Grid>
            )}
        </CreateReportModal>
    );
};

export default ReportManagementCreateModal;
